import React, { useEffect, useRef, useState } from 'react';

const OddsFeedComponent = ({ eventAccess, getMatchOddsHandle, getBookmakerHandle, getFancyHandle, getUnderOverHandle }) => {
  const [connected, setConnected] = useState(false);
  const [matchOdds, setMatchOdds] = useState(null);
  const [bookmaker, setBookmaker] = useState(null);
  const [underover, setUnderover] = useState([null, null, null, null]);
  const [fancy, setFancy] = useState(null);
  const subscribeChannels = useRef([]);
  const wsRef = useRef(null);
  let heartbeatInterval;

  useEffect(() => {
    getMatchOddsHandle(matchOdds);
  }, [matchOdds, getMatchOddsHandle]);

  useEffect(() => {
    getUnderOverHandle(underover);
  }, [underover, getUnderOverHandle]);

  useEffect(() => {
    getBookmakerHandle(bookmaker);
  }, [bookmaker, getBookmakerHandle]);

  useEffect(() => {
    getFancyHandle(fancy);
  }, [fancy, getFancyHandle]);

  const startWebSocket = () => {
    const ws = new WebSocket('wss://ag-api.supermaster.live/');
    wsRef.current = ws;

    ws.onopen = () => {
      // console.log('11111--Connected to WebSocket server');
      setConnected(true);

      // Subscribe to topics
      if (eventAccess?.bookmakerStatus) {
          if (!subscribeChannels.current.includes(`/topic/tommy_bm_update/${eventAccess?.bookmakerId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/tommy_bm_update/${eventAccess?.bookmakerId}` }));
            subscribeChannels.current.push(`/topic/tommy_bm_update/${eventAccess?.bookmakerId}`);
          }
      }else{
        // console.log("11111--BOOKMAKERS Not Found");
        }

      if (eventAccess?.matchoddStatus) {
          if (!subscribeChannels.current.includes(`/topic/betfair_match_odds_update/${eventAccess?.matchoddId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `/topic/betfair_match_odds_update/${eventAccess?.matchoddId}` }));
            subscribeChannels.current.push(`/topic/betfair_match_odds_update/${eventAccess?.matchoddId}`);
          }
      }else{
        // console.log("11111--MATCHODDS Not Found");
        }

      if (eventAccess?.sportId == 4 && eventAccess?.eventId) {
        const topic = `/topic/tommy_fancy_update/${eventAccess.matchId}`;
        if (!subscribeChannels.current.includes(topic)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic }));
          subscribeChannels.current.push(topic);
        }
      }else{
        // console.log("11111--FANCY Not Found");
        }

      heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ action: 'ping' }));
        }
      }, 30000); // 30 seconds
    };

    ws.onmessage = (event) => {
      const { topic, marketData } = JSON.parse(event.data);
        // console.log(`11111--${topic}----`,marketData);
        

      if (marketData) {
        const parsedData = JSON.parse(marketData);
        if (topic.startsWith('/topic/tommy_bm_update/')) {
          setBookmaker(parsedData);
        } else if (topic.startsWith('/topic/betfair_match_odds_update/')) {
          if (eventAccess?.MATCHODDS?.includes(topic.split('/').pop())) {
            setMatchOdds(parsedData);
          } else {
            const index = eventAccess.MATCHODDS.indexOf(topic.split('/').pop());
            if (index !== -1) {
              setUnderover((prev) => {
                const newState = [...prev];
                newState[index] = parsedData;
                return newState;
              });
            }
          }
        } else if (topic.startsWith('/topic/tommy_fancy_update/')) {
          setFancy(parsedData);
        }
      }
    };

    ws.onclose = () => {
      // console.log('11111--WebSocket disconnected');
      setConnected(false);
      clearInterval(heartbeatInterval);
      reconnectWebSocket();
    };

    ws.onerror = (error) => {
      // console.log('11111--WebSocket error:', error);
      clearInterval(heartbeatInterval);
      reconnectWebSocket();
    };
  };

  const reconnectWebSocket = () => {
    // console.log('11111--Reconnecting WebSocket...');
    setTimeout(() => {
      unsubscribeAll();
      startWebSocket();
    }, 3000); // Reconnect after 3 seconds
  };

  const unsubscribeAll = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      subscribeChannels.current.forEach((topic) => {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', topic }));
      });
      subscribeChannels.current = [];
    }
  };

  useEffect(() => {
    if (eventAccess) {
        // console.log('11111--eventAccess',eventAccess);
        
      // startWebSocket();
    }

    // return () => {
    //   if (wsRef.current) {
    //     wsRef.current.close();
    //     unsubscribeAll();
    //     clearInterval(heartbeatInterval);
    //   }
    // };
  }, [eventAccess]);

  return null;
};

export default OddsFeedComponent;
