import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Container from "../../components/common/Container";
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext';

export default function Slot() {
  document.title = 'Slot';
  const [royalCasinoApi, setRoyalCasino] = useState();
  const { openPopup, loggedIn, isLoggedOut } = useContext(PopupContext);
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
  const navigate = useNavigate();
  const initialized = useRef(false);

  const handlePlayNow = (item) => {
    navigate(`/live_casinoInPlay/${item._id}`);
  }

  useEffect(() => {
    setIsLoggedIn(sessionStorage.getItem('loggedIn'));
    if (!initialized.current) {
      initialized.current = true;
      callAPI();
    }
  }, []);

  const callAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/other_casinoPage?category=slot`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setRoyalCasino(paresData);
        console.log("Royal_casino - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Container size="full">
        <div className="content-full">
          <div className="home-grid-sec casino-grid-sec">
            {royalCasinoApi ?
              <div className="home-grid casino-grid">
                {royalCasinoApi &&
                  royalCasinoApi.games.map((item, index) => (
                    <div className="col" key={index}>
                      <Link>
                        <img src={item.url_thumb} />
                      </Link>
                      <div className="title">
                        {item.game_name}
                      </div>
                      <div className="login-btn-overlay">
                        {loggedIn || isLoggedIn ? <button onClick={() => handlePlayNow(item)} className="btn">Play now</button> : <button onClick={openPopup} className="btn">Login to continue</button>}
                      </div>
                    </div>
                  ))}
              </div> : <div className='please-wait-data'>Please wait data is loading...</div>}
            {royalCasinoApi && royalCasinoApi.games.length === 0 ? (
              <div className='no-data'>No Casino Found!</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </>
  )
}
