// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-slider{margin-top: 10px;}
.slick-slider .slick-slide img{border-radius: 15px;}

@media (max-width:480px) {
    .home-top-slider .slick-list { padding-left: 0 !important; }
}`, "",{"version":3,"sources":["webpack://./src/components/sliderreusable/slider-reusable.css"],"names":[],"mappings":"AAAA,cAAc,gBAAgB,CAAC;AAC/B,+BAA+B,mBAAmB,CAAC;;AAEnD;IACI,+BAA+B,0BAA0B,EAAE;AAC/D","sourcesContent":[".slick-slider{margin-top: 10px;}\n.slick-slider .slick-slide img{border-radius: 15px;}\n\n@media (max-width:480px) {\n    .home-top-slider .slick-list { padding-left: 0 !important; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
