import React, { useContext, useEffect, useState } from 'react'
import './home.css'
import SliderReusable from '../../components/sliderreusable/SliderReusable'
import GridBoxs from '../../components/common/grid/GridBoxs';
import BrowserBanner from '../../components/browserbanner/BrowserBanner';
import ServiceBox from '../../components/servicebox/ServiceBox';
import Container from '../../components/common/Container';

// Browse Banner
import browseBanne1 from '../../assets/images/homePosition1.webp'
import browseBanne2 from '../../assets/images/homePosition2.webp'
import HomeTab from '../../components/Hometab/HomeTab';
import SidebarSportMenu from '../../components/sidebarsportmenu/SidebarSportMenu';
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext';
function Home2() {

    const [assetsData, setAssetsData] = useState(null);
    const { mSideMenu } = useContext(PopupContext);
    useEffect(() => {
      document.title = localStorage.getItem('mainName');
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              'whiteLabelName': window.location.hostname,
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setAssetsData(data);
        } catch (error) {
          console.error('Fetch error:', error);
        }
      };

      fetchData();
    }, []);



    const homeSliderSettings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      speed: 500,
      infinite: true,
      autoplaySpeed: 5000,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '10%',
          },
        }
      ],
    };

    const BrowseBanner = [
      {image : `https://admin.${window.location.hostname}/img/homePosition1.webp`, title:'EXPLORE MORE THAN 250 CASINO GAMES', 'btntext':'BROWSE CASINO', url:'/live_casino' },
      {image : `https://admin.${window.location.hostname}/img/homePosition2.webp`, title:'PLACE A BET ON ANY SPORT AND MAKE MONEY', 'btntext':'BROWSE SPORT', url:'/exchange'},
  ]

  return (
    <>
    <Container size='full'>
    <div className='mobile-sport-menu'>
    <ul>
        <li> <a href="/"><span className='icon'><img src="../images/trophy.png" /></span> All Sports</a></li>
        <li> <a href="/"><span className='icon'><img src='../images/cricket-ball.png' /></span> Cricket</a> </li>
        <li> <a href="/"><span className='icon'><img src='../images/football.png' /></span> Football</a> </li>
        <li> <a href="/"><span className='icon'><img src='../images/tennis.png' /></span> Tennis</a> </li>
        <li> <a href="/"><span className='icon'><img src='../images/mayor.png' /></span> Election</a> </li>
        <li> <a href="/"><span className='icon'><img src='../images/kabaddi.png' /></span> Kabaddi</a> </li>
        <li> <a href="/"><span className='icon'><img src='../images/horse.png' /></span> Horse Racing</a> </li>
        <li> <a href="/"><span className='icon'><img src='../images/dog.png' /></span> Greyhound Racing</a> </li>
    </ul>
    </div>
      <div className='content-w-sidebar lc'>
      <div className={`left-content side-menu ${mSideMenu ? "open" : ''}`}>
        <SidebarSportMenu />
      </div>
        <div className='center-content'>
        <SliderReusable topSliderItem ={assetsData?.banner} settings={homeSliderSettings} className='home-top-slider' />
          <HomeTab />
          {assetsData && assetsData?.slider.map((slider,indexs)=>(
            <div key={indexs} className='home-grid-sec'>
              <h2 className="home-grid-title">{slider.name}</h2>
              <GridBoxs className="home-grid" GridData = {slider.images} title={slider.name}/>
          </div>
          ))}
          <div className='home-grid-sec browser-banner'>
            <BrowserBanner BannerData = {BrowseBanner} className="browser-banner-grid"/>
          </div>
          <div className='service-box-sec'>
            <ServiceBox servicesData={assetsData?.pages}/>
          </div>
        </div>
      </div>
    </Container>
    </>
  )
}

export default Home2