// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-menu-list { padding: 10px 10px; border-radius: 30px; background-color: var(--white-color); border: 1px solid var(--light-color); display: flex; flex-direction: column; margin-top: 10px; gap: 10px;}
.profile-menu-list a { display: flex; align-items: center; justify-content: start; width: 100%; padding: 5px; border-bottom: 1px solid var(--light-color); gap: 10px; font-size: 15px;}
.profile-menu-list a .icon{ height: 30px; width: 30px; border-radius: 40px; background-color: var(--light-color2); display: flex; align-items: center; justify-content: center;}
.profile-menu-list .fa-chevron-right{margin-left: auto;}`, "",{"version":3,"sources":["webpack://./src/pages/myprofile/myprofile.css"],"names":[],"mappings":"AAAA,qBAAqB,kBAAkB,EAAE,mBAAmB,EAAE,oCAAoC,EAAE,oCAAoC,EAAE,aAAa,EAAE,sBAAsB,EAAE,gBAAgB,EAAE,SAAS,CAAC;AAC7M,uBAAuB,aAAa,EAAE,mBAAmB,EAAE,sBAAsB,EAAE,WAAW,EAAE,YAAY,EAAE,2CAA2C,EAAE,SAAS,EAAE,eAAe,CAAC;AACtL,4BAA4B,YAAY,EAAE,WAAW,EAAE,mBAAmB,EAAE,qCAAqC,EAAE,aAAa,EAAE,mBAAmB,EAAE,uBAAuB,CAAC;AAC/K,qCAAqC,iBAAiB,CAAC","sourcesContent":[".profile-menu-list { padding: 10px 10px; border-radius: 30px; background-color: var(--white-color); border: 1px solid var(--light-color); display: flex; flex-direction: column; margin-top: 10px; gap: 10px;}\n.profile-menu-list a { display: flex; align-items: center; justify-content: start; width: 100%; padding: 5px; border-bottom: 1px solid var(--light-color); gap: 10px; font-size: 15px;}\n.profile-menu-list a .icon{ height: 30px; width: 30px; border-radius: 40px; background-color: var(--light-color2); display: flex; align-items: center; justify-content: center;}\n.profile-menu-list .fa-chevron-right{margin-left: auto;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
