import React, { useState, useEffect, useRef } from "react";
import Container from "../../components/common/Container";
import "react-datepicker/dist/react-datepicker.css";
import "./account-data.css";

export default function BonusStatment() {
  document.title = "Bonus Statement";
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [accountStatement, setAccountStatement] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isloader, setIsloader] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the state for the clicked row
    }));
  };

  const formatDatefromAPI = (isoDate) => {
    if (isoDate === "-") {
      return isoDate;
    }
    const date = new Date(isoDate);

    const optionsDate = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return `${formattedDate} at ${formattedTime}`;
  };

  const handleLoadMore =()=>{
    setCurrentPage(currentPage+1)
    callAccStatement(currentPage+1);
  }

  const callAccStatement = (currentPage) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    setIsloader(true);  

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    
    fetch(
      `${process.env.REACT_APP_BASE_URL}/bonusStatement?page=${currentPage}`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        if (paresData.bonusStatement.length === 0) {
          setHasMore(false);
        }else{
          setAccountStatement(prevData => [...prevData, ...paresData.bonusStatement]);
        }
        setIsloader(false);
      })
      .catch((error) => {
        setIsloader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callAccStatement(currentPage);
    }
  });

  return (
<>
{isloader ? <div className="loader-page"><span className="loader"></span></div> :''}
<Container size="full">
      <div className="account-sec">
        <div className="acount-header-filter">
          <div className="title-wraper">
            <span className="v-border"></span>
            <h3 className="title">Bonus Statement</h3>
          </div>
        </div>

        <div className="account-table">
          <table
            border="1"
            cellPadding="10"
            cellSpacing="0"
            className="user-data-table"
          >
            <thead>
              <tr>
                <th>Bonus Type</th>
                <th>Approval Required</th>
                <th>Awarded Date</th>
                <th>Awarded Amount</th>
                <th>Turnover</th>
                <th>Installments</th>
                <th>Redeemed Amount</th>
                <th>Bonus Status</th>
                <th>Last Redeemed Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accountStatement &&
                accountStatement.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => item.toggleData.length > 0 ? toggleRow(index) : ""} style={{ cursor: "pointer" }}>
                      <td>{item.bonusType ? item.bonusType : "-" }</td>
                      <td>{item.approvalRequired == null || item.approvalRequired === "" ? "-" : item.approvalRequired}</td>
                      <td>{item.awardedDate == null || item.awardedDate === "" ? "-" : formatDatefromAPI(item.awardedDate)}</td>
                      <td>{item.amount == null || item.amount === ""? "-": item.amount}</td>
                      <td>{item.turnover == null || item.turnover === ""? "-": item.turnover}</td>
                      <td>{item.installments == null || item.installments === ""? "-": item.installments}</td>
                      <td>{item.redeemedAmount == null || item.redeemedAmount === ""? "-": item.redeemedAmount}</td>
                      <td>{item.bonusStatus == null || item.bonusStatus === ""? "-": item.bonusStatus}</td>
                      <td>{item.lastRedeemedDate == null || item.lastRedeemedDate === ""? "-": formatDatefromAPI(item.lastRedeemedDate)}</td>
                      <td className={`${"arrow"}`}></td>
                    </tr>
                    {expandedRows[index] && (
                      <>
                        <tr className="address-header subtable-th">
                          <td colSpan={3}>Redeemed Date</td>
                          <td colSpan={2}>Amount</td>
                          <td colSpan={2}>Status</td>
                          <td colSpan={3}>Notes</td>
                        </tr>
                        {accountStatement[index].toggleData.map(
                          (item, index) => (
                            <tr key={index} className={`account-statment-expand`}>
                              <td colSpan={3}>{formatDatefromAPI(item.createdDate)}</td>
                              <td colSpan={2}>{item.bonusAmount}</td>
                              <td colSpan={2}>{item.bonusStatus}</td>
                              <td colSpan={3}>{item.note}</td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          {hasMore ? <div className="load-more-btn-sec">
            <button className="btn" onClick={handleLoadMore}>Load more</button>
          </div>:''}
        </div>
      </div>
    </Container>
</>
    
  );
}
