export const Utils = {
    getDateFormated :'dd-MM-yyyy'
};

export const convertToOriginalValue = (shorthand) => {
    
    // Ensure shorthand is a string
    if (typeof shorthand !== 'string') {
        return shorthand; // Return the value as is if it's not a string
    }

    // Regular expression to match a number followed by an optional suffix (K or M)
    const match = shorthand.match(/^([0-9]+(\.[0-9]+)?)\s*([KkMm])?$/);

    if (!match) {
        // Return the value as is if it doesn't match the expected format
        return shorthand;
    }

    const value = parseFloat(match[1]); // Extract numeric part
    const suffix = match[3]?.toUpperCase(); // Extract suffix and normalize to uppercase

    if (suffix === 'K') {
        return value * 1_000; // Multiply by 1,000 for 'K'
    } else if (suffix === 'M') {
        return value * 1_000_000; // Multiply by 1,000,000 for 'M'
    }
    return value; // Return the numeric value if no suffix
};

