import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Popup from "../../components/common/popup/Popup";

export default function Live_casinoInPlay({ headerRef }) {
  const initialized = useRef(false);
  const { gameId } = useParams();
  const [liveCasinoApi, setLiveCasino] = useState();
  const token = sessionStorage.getItem("token");
  const [mainHeight, setMainHeight] = useState(0);
   const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

  const callAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/live_casinoInPlay?gameId=${gameId}`,
      requestOptions
    )
      .then(async (response) => {
        console.log("response code : ",response.status)
        let paresData = await response.json();
        if(response.ok){
          setLiveCasino(paresData);
        }else if(response.status === 401){
          setIsError(true);
          setErrorMsg(paresData.message);
          setErrorDisable(); 
        }else if(response.status === 500){
          setIsError(true);
          setErrorMsg(paresData.message);
          setErrorDisable();
      }
    })  
      .catch((error) => {
        console.log(error.message);
      });
  };

  const setErrorDisable = () => {
    setTimeout(() => {
      setIsError(false);
      window.location.href = "/";
    }, 1000);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callAPI();
    }

    const adjustHeights = () => {
      const header = document.getElementById("header");
      const viewportHeight = window.innerHeight;
      const headerHeight = header.offsetHeight;

      // Calculate remaining height for main content
      setMainHeight(viewportHeight - headerHeight);
    };

    // Adjust heights on mount and on window resize
    adjustHeights();
    window.addEventListener("resize", adjustHeights);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", adjustHeights);
    };

  }, [liveCasinoApi]);

  return (
    <>
    <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <div className="game-iframe-sec" style={{
          height: `${mainHeight}px`,
        }}>
        {liveCasinoApi && <iframe
          src={liveCasinoApi.urldata.url} // Replace with the desired URL
          title={gameId}
        />}
      </div>
    </>
  );
}
