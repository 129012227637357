// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browser-banner .browser-banner-grid { display: flex; }
.browser-banner .browser-banner-grid .col{ width: 50%; padding: 0 5px; position: relative;}
.browser-banner .browser-banner-grid .overlay { position: absolute; top: 50%; z-index: 11; color: #fff; transform: translateY(-50%); padding: 2rem; }
.browser-banner .browser-banner-grid .overlay h4 { font-size: 30px; color: var(--white-color); font-weight: 600; max-width: 360px; line-height: 1.2; margin:0 0 18px; }
.browser-banner .browser-banner-grid .col img{width: 100%; border-radius: 30px;}

@media (max-width:999px) {
    .browser-banner .browser-banner-grid .overlay h4{font-size: 18px;}
    .browser-banner .browser-banner-grid .overlay { padding: 1rem; max-width: 260px; }
}
@media (max-width:767px) {
    .browser-banner .browser-banner-grid{flex-wrap: wrap; gap: 6px;}
    .browser-banner .browser-banner-grid .col{width: 100%;}
}
@media (max-width:480px) {
    .browser-banner .browser-banner-grid .col{padding: 0;}
    .browser-banner .browser-banner-grid .overlay { padding: 0.69em; max-width: 227px; }
    .browser-banner .browser-banner-grid .overlay h4{font-size: 17px; margin: 0 0 10px;}
    .browser-banner .browser-banner-grid .col img { border-radius: 15px; }
}`, "",{"version":3,"sources":["webpack://./src/components/browserbanner/browserbanner.css"],"names":[],"mappings":"AAAA,uCAAuC,aAAa,EAAE;AACtD,2CAA2C,UAAU,EAAE,cAAc,EAAE,kBAAkB,CAAC;AAC1F,gDAAgD,kBAAkB,EAAE,QAAQ,EAAE,WAAW,EAAE,WAAW,EAAE,2BAA2B,EAAE,aAAa,EAAE;AACpJ,mDAAmD,eAAe,EAAE,yBAAyB,EAAE,gBAAgB,EAAE,gBAAgB,EAAE,gBAAgB,EAAE,eAAe,EAAE;AACtK,8CAA8C,WAAW,EAAE,mBAAmB,CAAC;;AAE/E;IACI,iDAAiD,eAAe,CAAC;IACjE,gDAAgD,aAAa,EAAE,gBAAgB,EAAE;AACrF;AACA;IACI,qCAAqC,eAAe,EAAE,QAAQ,CAAC;IAC/D,0CAA0C,WAAW,CAAC;AAC1D;AACA;IACI,0CAA0C,UAAU,CAAC;IACrD,gDAAgD,eAAe,EAAE,gBAAgB,EAAE;IACnF,iDAAiD,eAAe,EAAE,gBAAgB,CAAC;IACnF,gDAAgD,mBAAmB,EAAE;AACzE","sourcesContent":[".browser-banner .browser-banner-grid { display: flex; }\n.browser-banner .browser-banner-grid .col{ width: 50%; padding: 0 5px; position: relative;}\n.browser-banner .browser-banner-grid .overlay { position: absolute; top: 50%; z-index: 11; color: #fff; transform: translateY(-50%); padding: 2rem; }\n.browser-banner .browser-banner-grid .overlay h4 { font-size: 30px; color: var(--white-color); font-weight: 600; max-width: 360px; line-height: 1.2; margin:0 0 18px; }\n.browser-banner .browser-banner-grid .col img{width: 100%; border-radius: 30px;}\n\n@media (max-width:999px) {\n    .browser-banner .browser-banner-grid .overlay h4{font-size: 18px;}\n    .browser-banner .browser-banner-grid .overlay { padding: 1rem; max-width: 260px; }\n}\n@media (max-width:767px) {\n    .browser-banner .browser-banner-grid{flex-wrap: wrap; gap: 6px;}\n    .browser-banner .browser-banner-grid .col{width: 100%;}\n}\n@media (max-width:480px) {\n    .browser-banner .browser-banner-grid .col{padding: 0;}\n    .browser-banner .browser-banner-grid .overlay { padding: 0.69em; max-width: 227px; }\n    .browser-banner .browser-banner-grid .overlay h4{font-size: 17px; margin: 0 0 10px;}\n    .browser-banner .browser-banner-grid .col img { border-radius: 15px; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
