import React, { useContext, useEffect, useState } from 'react'
import { PopupContext } from '../LoginPopup/LoginPopupContext';
import StringToHtml from '../../StringToHtml';

function MobileLiveStreamTab({ matchStatus, evId, spId, receivedBetlistData,url }) {
  const [tabActive, setTabactive] = useState(matchStatus == "IN_PLAY" ? 'tab3':'tab1');
  const [liveStreamSlide, setLiveStreamSlide] = useState('open');
  const { loggedIn } = useContext(PopupContext);
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
  const [betLoginShow, setBetLoginShow] = useState();

  useEffect(() => {
    // console.log(spId,"spIdspIdspIdspIdspId");
    
    if(isLoggedIn || loggedIn){
      setBetLoginShow(true)
    }else{
      setBetLoginShow(false)
    }
  }, [loggedIn, isLoggedIn, betLoginShow,url])

  const tabHandle = (name) => {
    setTabactive(name)
  }
  const liveStreamHandle = () => {
    setLiveStreamSlide(!liveStreamSlide)
  }

  const getIframe = (url) => {
    return <StringToHtml htmlString={url}  />
}

  // console.log('receivedBetlistDatareceivedBetlistDataere', receivedBetlistData);
  return (
    <div className='live-stream-mobile-tab'>
      <div className='live-stream-tab-btns'>
        {matchStatus == "IN_PLAY" ? <>
          <button className={`btn ${tabActive === 'tab1' ? 'active' : ''}`} onClick={() => tabHandle('tab1')}>Score</button>
          <button className={`btn ${tabActive === 'tab2' ? 'active' : ''}`} onClick={() => tabHandle('tab2')}>Live Stream</button>
        </>: '' }
        <button className={`btn ${tabActive === 'tab3' ? 'active' : ''}`} onClick={() => tabHandle("tab3")}>Bets ({receivedBetlistData?.bets?.length})</button>
      </div>
      <div className='live-stream-tab-content'>
        <div className={`live-stream-tab-panel ${tabActive === 'tab1' ? 'active' : ''}`}>

        {matchStatus == "IN_PLAY" ? getIframe(url) :''}
          
        </div>
        <div className={`live-stream-tab-panel ${tabActive === 'tab2' ? 'active' : ''}`}>
          <div className='live-stream'>
            <div className='title live-stream-title' onClick={liveStreamHandle}>Live Stream</div>
            <div className={`livestream-iframe ${liveStreamSlide && liveStreamSlide ? 'open' : ''}`}>
              <iframe src={`https://tv-quq1tgjxfpeu.fstlive.video/?eventid=${evId}`} ></iframe>
            </div>
          </div>
        </div>
        <div className={`live-stream-tab-panel ${tabActive === 'tab3' ? 'active' : ''}`}>
          <div className='title'>Open Bets ({receivedBetlistData?.bets?.length})</div>
          <div className='betlist'>
            {betLoginShow ?
            <table className="betlist-table">
              <thead>
                <tr>
                  <th>Selection</th>
                  <th>Odds</th>
                  <th>Market</th>
                  <th>Stake</th>
                </tr>
              </thead>
              <tbody>
                {receivedBetlistData?.bets?.map((item, index) => (
                  <tr key={index} className={`${item?.bettype2 === 'BACK' ? 'back' : 'lay'}`}>
                    <td> {item?.selectionName}</td>
                    <td> {item?.oddValue}</td>
                    <td> {item?.marketName}</td>
                    <td> {item?.Stake}</td>
                  </tr>
                ))}
              </tbody>
            </table> : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileLiveStreamTab