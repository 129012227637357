import React, { useState, useEffect, useContext, useRef } from "react";
import Container from "../../components/common/Container";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";
import { Swiper, SwiperSlide  } from 'swiper/react';
import searchIcon from "../../assets/images/search-icon.png";
import Banner from "../../components/common/Banner";

export default function Royal_casino() {
  document.title = "Royal Casino";
  const [royalCasinoApi, setRoyalCasino] = useState();
  const { openPopup, loggedIn, isLoggedOut } = useContext(PopupContext);
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
  const navigate = useNavigate();
  const initialized = useRef(false);
  const [gameName, setgameName] = useState("");
  const [gameTab, setGameTab] = useState('All');
  const [dropdown, setDropdown] = useState();
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
      const [results, setResults] = useState();

  const handlePlayNow = (item) => {
    navigate(`/live_casinoInPlay/${item._id}`);
  }


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false); // Close the dropdown if clicked outside
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [dropdownRef]);

  useEffect(() => {
    setIsLoggedIn(sessionStorage.getItem('loggedIn'));
    if (!initialized.current) {
      initialized.current = true;
      callAPI("All");
    }
  }, []);

  const callAPI = (type) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/new_royalcasino?provider=Royal Gaming&type=${type}`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setRoyalCasino(paresData);
        setgameName(paresData.gameNameAll)
        console.log("Royal_casino - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleType = (type) =>{
    callAPI(type);
    setGameTab(type);
  }

  const dropdownHandle = () => {
    setDropdown(!dropdown);
  }

  const handleSearchChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length > 1) {
      setShowSuggestions(true);

      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          whiteLabelName: window.location.hostname,
          search: value,
        });
        console.log("gameSearch Req", raw);
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/games/gameSearch`,
          requestOptions
        )
          .then(async (response) => {
            let paresData = await response.json();
            setResults(paresData);
            console.log("gameSearch Response : ", paresData);
          })
          .catch((error) => {
            console.log("gameSearch Error : ", error);
          });
      } catch (err) {
        console.log("gameSearch Error : ", err.message);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (item) => {
    if(isLoggedIn || loggedIn){
      console.log("handleSuggestionClick : ", item);
      navigate(`/live_casinoInPlay/${item._id}`);
      handleClear();
      setShowSuggestions(false); // Hide suggestions when user selects one
    }else{
      openPopup();
    }
  };

  const handleClear = () => {
    console.log("no Record found! clear text");
    setSearchTerm("");
    setResults();
  };
  return (
    <>
      <Container size="full">
        <div className="content-full">
        <Banner image={`https://admin.${window.location.hostname}/img/royal_top.webp`} className='live-casino-banner' />
        <div className="search-with-swiper">
          <Swiper className="game-category-list" spaceBetween={10} slidesPerView={'auto'} onSlideChange={() => console.log('slide change')} onSwiper={(swiper) => console.log(swiper)} >

            {gameName && gameName.map((item, index) => (
              <SwiperSlide key={index} style={{ width: 'auto' }}>
                <div onClick={()=>handleType(item.id)} className={`game-tab ${item.id === gameTab ? 'active': ''}`}>
                  <span className="icon"><img src={item.url} /></span>
                  {item.name}
                </div> </SwiperSlide>
            ))}
          </Swiper>
          <div className="dropdown search-dropdown">
              <button className="search-icon" onClick={dropdownHandle}><span className="icon"><img src={searchIcon} /></span> Search</button>
              <div className={`dropdown-menu ${dropdown ? 'active' : ''}`} ref={dropdownRef}>
                <input
                  type="text"
                  placeholder="Search games..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={() => setShowSuggestions(true)}
                />
                {results && showSuggestions && results.games.length > 0 && (
                  <ul className="search-result">
                    {results.games.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(item)}
                      >
                        {item.game_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="home-grid-sec casino-grid-sec">
            {royalCasinoApi ? <div className="home-grid casino-grid">
              {royalCasinoApi &&
                royalCasinoApi.games.map((item, index) => (
                  <div className="col" key={index}>
                    <Link>
                      <img src={item.url_thumb} />
                    </Link>
                    <div className="title">
                      {item.game_name}
                    </div>
                    <div className="login-btn-overlay">
                      {loggedIn || isLoggedIn ? <button onClick={() => handlePlayNow(item)} className="btn">Play now</button> : <button onClick={openPopup} className="btn">Login to continue</button>}
                    </div>
                  </div>
                ))}
            </div> : <div className='please-wait-data'>Please wait data is loading...</div>}
            {royalCasinoApi && royalCasinoApi.games.length === 0 ? (
                <div className="no-data">No Casino Found!</div>
              ) : (
                ""
              )}
            </div>
        </div>
      </Container>
    </>
  )
}
