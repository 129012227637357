import React, { useContext, useEffect, useState } from 'react'
import useSocket from '../../../Websoket.js';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { PopupContext } from '../../LoginPopup/LoginPopupContext.js';
import betslipclosearrow from '../../../assets/images/betslip-close-arrow.png'
import FancyBookPopup from './FancyBookPopup.js';


function FancyBallByBall({ eventAccess, categorizedData, getfancyClick, receivedBetlistData }) {
    const fancySession = {
        "eventId": eventAccess?.matchData?.eventId
    };
    const isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if (isOneClicked == 'true') {
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }
        const [firstTimeCall, setFirstTimeCall] = useState(false)

    const [fancysessionData, setFancysessionData] = useState(fancySession);
    const { isConnected, receivedFancyballByBall, sendMessage, receivedBookFancy } = useSocket();
    const [slideToggle, setSlideToggle] = useState(false)
    const [priceGet, setPriceGet] = useState(0);
    const [oddsValue, setoddsValue] = useState('');
    const [bklyClassValue, setBklyClass] = useState();
    const [betLoader, setBetLoader] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const { stackArray, openPopup, loggedIn, isLoggedOut, direactsecId, setSecIds, clearSecId, betSlipOpen, closeBetSlip, openBetSlip,isBetLoader, loaderCounter } = useContext(PopupContext);
    const [Beton, setBeton] = useState();
    const [stack, setStack] = useState([]);
    const formatNumberinStack = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000) + 'K'; // Thousand
        } else {
          return num; // Less than 1000
        }
      };
    const [plusMinusValue, setplusMinusValue] = useState();

    const [fancyBookPopup, setFancyBookPopup] = useState()

    const convertToOriginalValue = (shorthand) => {
        // Ensure shorthand is a string
        if (typeof shorthand !== 'string') {
            return shorthand; // Return the value as is if it's not a string
        }

        const value = parseFloat(shorthand); // Extract numeric part
        if (shorthand.toUpperCase().endsWith('K')) {
            return value * 1_000; // Multiply by 1,000 for 'K'
        } else if (shorthand.toUpperCase().endsWith('M')) {
            return value * 1_000_000; // Multiply by 1,000,000 for 'M'
        }
        return value; // Return the numeric value if no suffix
    };

    const setLockValue = (oddItem,type) => {
        if (type === "no") {
            if (oddItem != undefined && oddItem?.noValue != 0 && oddItem?.noValue != undefined) {
                return (<><strong>{oddItem?.noValue}</strong> <small>{oddItem?.noRate}</small></>)
            }else{
                return (<><i className="fa fa-lock"></i></>);
            }
        }else{
            if (oddItem != undefined && oddItem?.yesValue != 0 && oddItem?.yesValue != undefined) {
                return (<><strong>{oddItem?.yesValue}</strong> <small>{oddItem?.yesRate}</small></>)

            }else{
                return (<><i className="fa fa-lock"></i></>);
            }
        }
    }

const allValue = () => {
    let userinfo = sessionStorage.getItem('balance');
    if (userinfo !== null) {
        let data = JSON.parse(userinfo);
        return (data.availableBalance - data.totalExposure).toFixed(2);
    }else{
        return 0;
    }
};

    useEffect(() => {
        // console.log(eventAccess,"-----------eventAccess");
        // console.log(categorizedData,"-----------categorizedData");
        // console.log(getfancyClick,"-----------getfancyClick");
        // console.log(receivedBetlistData,"-----------receivedBetlistData");
        // console.log(receivedFancyballByBall,"-----------receivedFancyballByBall");

        let betLoderInterval;
        // betLoderInterval = setInterval(() => {
            setBetLoader(false);
        // }, 1500);
        // return () => {
            // if (betLoderInterval) {
                // clearInterval(betLoderInterval);
            // }
        // };
    }, [receivedFancyballByBall,categorizedData]);

    const getNoValueCheck = (receivedFancyballByBall, oddItem, type) => {
        if (type === "no") {
            if (receivedFancyballByBall?.finalResult) {
                const matchedItem = receivedFancyballByBall.finalResult.find(item => item.marketName === oddItem.marketName);
                if (matchedItem?.max_odd !== undefined) {
                    return matchedItem.max_odd < oddItem?.noValue;
                } else {
                    return oddItem?.max_odd < oddItem?.noValue;
                }
            } else {
                return oddItem?.max_odd < oddItem?.noValue;
            }
        } else { // type is not "no"
            if (receivedFancyballByBall?.finalResult) {
                const matchedItem = receivedFancyballByBall.finalResult.find(item => item.marketName === oddItem.marketName);
                if (matchedItem?.max_odd !== undefined) {
                    return matchedItem.max_odd < oddItem?.yesValue;
                } else {
                    return oddItem?.max_odd < oddItem?.yesValue;
                }
            } else {
                return oddItem?.max_odd < oddItem?.noValue;
            }
        }
    };


    const getBetSlipClasss = (oddItem) => {
        if (direactsecId === oddItem.marketId) {
            if(oddItem.status !== 'OPEN'){
                clearSecId()
                return 'betslip-wraper'
            }else{
                return 'betslip-wraper open'
            }
        }
        return 'betslip-wraper';
      };

    const handleShow = (marketId) => {
        setFancyBookPopup(true)
        let userinfo = sessionStorage.getItem('userinfo');
        if (userinfo && marketId) {
            userinfo = JSON.parse(userinfo);
            sendMessage('getFancyBookDATAuserSide', { id: marketId, user: userinfo });
        }
    }
    const handleClose = () => {
        setFancyBookPopup(false)
    }

    const addAllValue = (newValue) => {
        setplusMinusValue(newValue)
            setPriceGet(newValue);
    };

    const addValue = (newValue) => {
        setplusMinusValue(newValue)
        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }
    };

    const PlusValue = () => {
        const newValue = plusMinusValue ? plusMinusValue : 100


        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }
    };

    const subtractValue = () => {
        const valueToSubtract = plusMinusValue ? plusMinusValue : 100
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            const newPrice = currentPrice - valueToSubtract;
            return newPrice < 0 ? 0 : newPrice;
        });
    };

    const incrementValue = (increment) => {
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            return currentPrice + increment;
        });
    };

    const removeLastDigit = () => {
        setPriceGet(prevPrice => {
            if (prevPrice !== undefined && prevPrice !== null) {
                const newPrice = String(prevPrice).slice(0, -1);
                return newPrice === '' ? 0 : Number(newPrice);
            }
            return 0;
        });
    };

    useEffect(() => {
        let intervalId;
        if (isConnected && fancysessionData) {
            let matchdata = null;
            let userData = sessionStorage.getItem('userId')
            if(userData){
                matchdata = {
                    "eventId": eventAccess?.matchData?.eventId,
                    "userId": userData
                }
            }else{
                matchdata = {
                    "eventId": eventAccess?.matchData?.eventId
                }
            }
            if (!firstTimeCall) {
                setFirstTimeCall(true)
                sendMessage('ballbyballmaketIds', matchdata);
            }
            intervalId = setInterval(() => {
                sendMessage('ballbyballmaketIds', matchdata);
            }, 5000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConnected, fancysessionData, sendMessage, direactsecId]);

    const toggleHandle = (slideId, bklyClass, event, runnerName) => {
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
        console.log('spanText spanText spanText', strongElement);
        setSlideToggle(slideToggle === slideId ? slideId : slideId);
        setBklyClass(bklyClass);
        setoddsValue(strongElement);
        setSecIds(slideId);
        openBetSlip();
        setPriceGet('')
        setBeton(runnerName)
    }


    // console.log(isOneClicked, 'isOneClickedisOneClickedisOneClicked');
    const onClickFunction = (runnerId, toggleclass, event, runnerName, oddItem) => {
        event.persist();
        setBetLoader(true);
        setSlideToggle(slideToggle === runnerId ? runnerId : runnerId);
        setSecIds(runnerId);
        setBklyClass(toggleclass);
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
        setoddsValue(strongElement);
        setPriceGet(oneClickValue)
        getfancyClick({ eventid: receivedFancyballByBall?.finalResult?.[0]?.eventId, marketId: runnerId, stake: oneClickValue * 1, bklyClassValue: toggleclass, selectionName: runnerName, rate: strongElement * 1, odds: toggleclass === 'back' ? oddItem?.yesRate : oddItem?.noRate })
    }

    useEffect(() => {
        try{
            setStack(JSON.parse(stackArray));
            if(!isBetLoader){
                handlecloseSlip();
            }
        }catch(err){
            console.log(err, 'errerrerrerr');
            setStack(stackArray)
            if(!isBetLoader){
                handlecloseSlip();
            }
        }
      }, [isBetLoader, loaderCounter,stackArray]);

    const handlecloseSlip = () => {
        setSlideToggle(false);
        clearSecId();
        closeBetSlip();
        setPriceGet('')
    }

    function isSuspendedFancy(receivedFancyballByBall, oddItem, direactsecId) {
        const matchingItem = receivedFancyballByBall?.finalResult?.find(
          item => item.marketName === oddItem.marketName
        );

        if (matchingItem?.resumesuspendstatus) {
          return oddItem?.status !== 'OPEN' && direactsecId !== oddItem.marketId;
        }

        return true; // Suspended if no matchingItem or resumesuspendstatus is false
      }


    function isSuspended(oddItem, direactsecId) {
        if (oddItem?.resumesuspendstatus) {
          return oddItem?.status !== 'OPEN' && direactsecId !== oddItem.marketId;
        }
        return true; // Suspended if resumesuspendstatus is false
      }

        const setSensionComission = (oddItem) => {
            if (Array.isArray(receivedFancyballByBall.finalResult)){
                for (let index = 0; index < receivedFancyballByBall.finalResult.length; index++) {
                    const element = receivedFancyballByBall.finalResult[index];
                    if (element.marketId===oddItem.marketId) {
                        if(element.commissionstatus){
                            return <span className='blink'>*</span>;
                        } else {
                            return '';
                        }
                    }
                }
            }
        }


    return (
        <>
            {eventAccess?.ballByBall ? <>
                <Accordion transition transitionTimeout={350}>
                <AccordionItem initialEntered header={<div className='heading match-heading'>
                    <div className='heading-text sport-name'>Ball By Ball</div>
                    <div className='odds-heading col2-odds-heading'>
                        <div className='heading-text box pink'>No</div>
                        <div className='heading-text box blue'>Yes</div>
                    </div>
                </div>}>
                <div className='match-data'>
                    <div className='match-list'>
                        {categorizedData.length === 0 ?
                            <>{receivedFancyballByBall?.finalResult?.map((oddItem, index) => (
                                <div className='data-with-betslip' key={index}>
                                    {/* {console.log("oddItemoddItemoddItem : ",oddItem," ------- ",direactsecId)} */}
                                    <div className='match-info-row'>
                                        <div className='match-name'>{oddItem.marketName} {oddItem.commissionstatus ? <span className='blink'>*</span> :''}
                                            {
                                                receivedBetlistData && receivedBetlistData.bets && receivedBetlistData.bets.filter(item => item.marketId === oddItem.marketId).length > 0 ? (
                                                    <button className="btn fancy-book-btn" onClick={() => handleShow(oddItem.marketId)}>Book</button>
                                                ) : null
                                            }
                                        </div>
                                        <div className='market-limit'>
                                            <span>Min : {oddItem?.min_stake}, </span>
                                            <span>Max : {oddItem?.max_stake}</span>
                                        </div>
                                        <div className='match-odds'>

                                        {isSuspended(oddItem, direactsecId) ? (
                                        <>
                                            <div className='suspended'>{'Suspended'}</div>
                                        </>
                                            ) : null}
                                        {/* {oddItem.resumesuspendstatus ? (
                                                oddItem?.status !== 'ACTIVE' ? (
                                                direactsecId === oddItem.marketId ? '' : <div className='suspended'>{oddItem?.status}</div>
                                            ) : ''
                                            ) : (<div className='suspended'>suspended</div>)} */}

                                            <div className='box'>
                                                <span
                                                 className={`pink ${oddItem?.max_odd < oddItem?.noValue ? 'disable' : ''}`}
                                                 onClick={(event) => {
                                                    if(!isBetLoader){
                                                    if (isOneClicked === "true")
                                                    {
                                                        onClickFunction(oddItem.marketId, 'lay', event, oddItem.marketName, oddItem);
                                                    } else {
                                                        toggleHandle(oddItem.marketId, 'lay', event, oddItem.marketName);
                                                    }
                                                    }
                                                    }}>
                                                     {oddItem?.status === 'OPEN' ? (
                                                        <>{setLockValue(oddItem,"no")}</>
                                                    ) : (
                                                        <i className="fa fa-lock"></i>
                                                    )}
                                                 </span>
                                            </div>
                                            <div className='box' onClick={(event) => {
                                                if(!isBetLoader){
                                                if (isOneClicked === "true") {
                                                    onClickFunction(oddItem.marketId, 'back', event, oddItem.marketName, oddItem);
                                                } else {
                                                    toggleHandle(oddItem.marketId, 'back', event, oddItem.marketName);
                                                }
                                                }
                                                 }}>
                                                <span
                                                className={`blue ${oddItem?.max_odd < oddItem?.yesValue ? 'disable' : ''}`}>
                                                {oddItem?.status === 'OPEN' ? (
                                                    <>{setLockValue(oddItem,"yes")}</>
                                                    ) : (
                                                        <i className="fa fa-lock"></i>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={direactsecId} className={getBetSlipClasss(oddItem)}>
                                        <div className={`betslip ${direactsecId === oddItem.marketId ? 'active' : ''} ${bklyClassValue}`}>
                                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                            <div className="betslip-top">
                                                <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                <div className="bet-profit"></div>
                                                <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                <div className="betslip-stake">
                                                    <strong>Stake</strong>
                                                    <div className="input-group">
                                                        <span className="minus" onClick={() => {
                                                            setPriceGet((prevPrice) => (Number(prevPrice) >= 100 ? Number(prevPrice) - 100 : 0));
                                                        }}>-</span>
                                                        <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPriceGet(value);
    }
  }}  aria-label="Last name"
                                                            autoFocus="" maxLength="8" />
                                                        <span className="plus" onClick={() => { setPriceGet((prevPrice) => Number(prevPrice) + 100) }}>+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="betslip-placebutton">
                                                <div className="price-btns">
                                                {Array.isArray(stack) && stack.map((item,index) => (
                                                            <span key={index} id={`${item}`} onClick={(event) => addValue(`${item}`)}>{formatNumberinStack(item)}</span>
                                                        )) }
                                                    <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)}>MAX</span>
                                                    <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event)}>All</span>
                                                </div>
                                                <div className="betplace-btn">
                                                    {loggedIn || isLoggedIn ? <button className="place-bet" onClick={() => {
                                                        {
                                                            setBetLoader(true);
                                                            getfancyClick({ eventid: receivedFancyballByBall?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                        }
                                                    }}>Place Bet</button> : <button className="login-btn" onClick={openPopup}>Login to continue</button>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`betslip_mobileview ${direactsecId === oddItem.marketId ? 'active' : ''} ${bklyClassValue}`}>
                                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                            <table className="eventdetails">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className='plus-minus-wraper'>
                                                                <div className='plus-minus'>
                                                                    <span>-</span>
                                                                    <input type="text" value={oddsValue} />
                                                                    <span>+</span>
                                                                </div>
                                                                <div className='plus-minus'>
                                                                    <span onClick={() => subtractValue()}>-</span>
                                                                    <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPriceGet(value);
    }
  }}  aria-label="Last name" autoFocus="" maxLength="8" />
                                                                    <span onClick={() => PlusValue()} >+</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="11" className='mbet-slip'>
                                                            <div className="mbet-slip-wraper">
                                                            {Array.isArray(stack) && stack.map((item,index) => (
                                                                    <div key={index} id={`${item}`} className="odds-value" onClick={(event) => addValue(`${item}`)}>{formatNumberinStack(item)}</div>
                                                                )) }
                                                                <div className="odds-value max" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)} id="10000">MAX</div>
                                                                <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event)} id="98941.80">ALL IN</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="numbering">
                                                        <td colSpan="11">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span onClick={() => incrementValue('1')} id="1">1</span></td>
                                                                        <td><span onClick={() => incrementValue('2')} id="2">2</span></td>
                                                                        <td><span onClick={() => incrementValue('3')} id="3">3</span></td>
                                                                        <td><span onClick={() => incrementValue('4')} id="4">4</span></td>
                                                                        <td><span onClick={() => incrementValue('5')} id="5">5</span></td>
                                                                        <td><span onClick={() => incrementValue('6')} id="6">6</span></td>
                                                                        <td rowSpan="2"><span className="back-span" onClick={() => removeLastDigit()}><img alt="" src={betslipclosearrow} /></span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span onClick={() => incrementValue('7')} id="7">7</span></td>
                                                                        <td><span onClick={() => incrementValue('8')} id="8">8</span></td>
                                                                        <td><span onClick={() => incrementValue('9')} id="9">9</span></td>
                                                                        <td><span onClick={() => incrementValue('0')} id="0">0</span></td>
                                                                        <td><span onClick={() => incrementValue('00')} id="double0">00</span></td>
                                                                        {/* <td><span onClick={() => incrementValue('000')} id="dot">000</span></td> */}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr className="bet-btns">
                                                        <td colSpan="11">
                                                            <div className="btn-wraper">
                                                                <button className="btn cancel-bet">CANCEL</button>
                                                                {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={() => {
                                                                    {
                                                                        setBetLoader(true);
                                                                        getfancyClick({ eventid: receivedFancyballByBall?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                                    }
                                                                }}>Place Bet</button> : <button className="btn place-bet" onClick={openPopup}>Login to continue</button>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {isBetLoader ?
                                            <div className="loader-sec loading">
                                                Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                            </div> : ''}
                                    </div>
                                </div>
                            ))}</> : <>{categorizedData.map((oddItem, index) => (
                                <div className='data-with-betslip' key={index}>
                                    <div className='match-info-row'>
                                        <div className='match-name'>{oddItem.marketName}{setSensionComission(oddItem)}
                                            {
                                                receivedBetlistData && receivedBetlistData.bets && receivedBetlistData.bets.filter(item => item.marketId === oddItem.marketId).length > 0 ? (
                                                    <button className="btn fancy-book-btn" onClick={() => handleShow(oddItem.marketId)}>Book</button>
                                                ) : null
                                            }
                                        </div>
                                        <div className='market-limit'>
                                            <span>Min : {receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.min_stake}, </span>
                                            <span>Max : {receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake}</span>
                                        </div>
                                        <div className='match-odds'>
                                        {receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.resumesuspendstatus ? (
                                                oddItem?.status !== 'OPEN' ? (
                                                direactsecId === oddItem.marketId ? '' : <div className='suspended'>{oddItem?.status}</div>
                                            ) : ''
                                            ) : (<div className='suspended'>suspended</div>)}

                                            {/* {isSuspendedFancy(receivedFancyballByBall, oddItem, direactsecId) ? (
                                            <div className='suspended'>{'Suspended'}</div>
                                            ) : ''} */}
                                            <div className='box'>
                                                <span className={`pink ${getNoValueCheck(receivedFancyballByBall,oddItem,'no') ? 'disable' : ''}`} onClick={(event) => { if(!isBetLoader){if (isOneClicked === "true") { onClickFunction(oddItem.marketId, 'lay', event, oddItem.marketName, oddItem); } else { toggleHandle(oddItem.marketId, 'lay', event, oddItem.marketName); }} }}>
                                                {oddItem ? <>{setLockValue(oddItem,"no")}</> : <i className="fa fa-lock"></i>}</span>
                                            </div>
                                            <div className='box'>
                                                <span className={`blue ${getNoValueCheck(receivedFancyballByBall,oddItem,'yes') ? 'disable' : ''}`} onClick={(event) => { if(!isBetLoader){if (isOneClicked === "true") { onClickFunction(oddItem.marketId, 'back', event, oddItem.marketName, oddItem); } else { toggleHandle(oddItem.marketId, 'back', event, oddItem.marketName); } }}}>
                                                {oddItem ? <>{setLockValue(oddItem,"yes")}</> : <i className="fa fa-lock"></i>}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={direactsecId} className={getBetSlipClasss(oddItem)}>
                                        <div className={`betslip ${direactsecId === oddItem.marketId ? 'active' : ''} ${bklyClassValue}`}>
                                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                            <div className="betslip-top">
                                                <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                <div className="bet-profit"></div>
                                                <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                <div className="betslip-stake">
                                                    <strong>Stake</strong>
                                                    <div className="input-group">
                                                        <span className="minus" onClick={() => {
                                                            setPriceGet((prevPrice) => (Number(prevPrice) >= 100 ? Number(prevPrice) - 100 : 0));
                                                        }}>-</span>
                                                        <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*$/.test(value)) {
                                                            setPriceGet(value);
                                                            }
                                                        }}  aria-label="Last name" autoFocus="" maxLength="8" />
                                                        <span className="plus" onClick={() => { setPriceGet((prevPrice) => Number(prevPrice) + 100) }}>+</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="betslip-placebutton">
                                                <div className="price-btns">
                                                {Array.isArray(stack) && stack.map((item,index) => (
                                                            <span key={index} id={`${item}`} onClick={(event) => addValue(`${item}`)}>{formatNumberinStack(item)}</span>
                                                        )) }
                                                    <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)}>MAX</span>
                                                    <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event)}>All</span>
                                                </div>
                                                <div className="betplace-btn">
                                                    {loggedIn || isLoggedIn ? <button className="place-bet" onClick={() => {
                                                           { setBetLoader(true);
                                                            getfancyClick({ eventid: receivedFancyballByBall?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })}
                                                    }}>Place Bet</button> : <button className="login-btn" onClick={openPopup}>Login to continue</button>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`betslip_mobileview ${direactsecId === oddItem.marketId ? 'active' : ''} ${bklyClassValue}`}>
                                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                            <table className="eventdetails">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className='plus-minus-wraper'>
                                                                <div className='plus-minus'>
                                                                    <span>-</span>
                                                                    <input type="text" value={oddsValue}/>
                                                                    <span>+</span>
                                                                </div>
                                                                <div className='plus-minus'>
                                                                    <span onClick={() => subtractValue()}>-</span>
                                                                    <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        if (/^\d*$/.test(value)) {
                                                                        setPriceGet(value);
                                                                        }
                                                                    }}  aria-label="Last name" autoFocus="" maxLength="8" />
                                                                    <span onClick={() => PlusValue()} >+</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="11" className='mbet-slip'>
                                                            <div className="mbet-slip-wraper">
                                                            {Array.isArray(stack) && stack.map((item,index) => (
                                                                    <div key={index} id={`${item}`} className="odds-value" onClick={(event) => addValue(`${item}`)}>{formatNumberinStack(item)}</div>
                                                                )) }
                                                                <div className="odds-value max" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedFancyballByBall?.finalResult?.find(item => item.marketName === oddItem.marketName)?.max_stake)}`,event)} id="10000">MAX</div>
                                                                <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event)} id="98941.80">ALL IN</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="numbering">
                                                        <td colSpan="11">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span onClick={() => incrementValue('1')} id="1">1</span></td>
                                                                        <td><span onClick={() => incrementValue('2')} id="2">2</span></td>
                                                                        <td><span onClick={() => incrementValue('3')} id="3">3</span></td>
                                                                        <td><span onClick={() => incrementValue('4')} id="4">4</span></td>
                                                                        <td><span onClick={() => incrementValue('5')} id="5">5</span></td>
                                                                        <td><span onClick={() => incrementValue('6')} id="6">6</span></td>
                                                                        <td rowSpan="2"><span className="back-span" onClick={() => removeLastDigit()}><img alt="" src={betslipclosearrow} /></span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span onClick={() => incrementValue('7')} id="7">7</span></td>
                                                                        <td><span onClick={() => incrementValue('8')} id="8">8</span></td>
                                                                        <td><span onClick={() => incrementValue('9')} id="9">9</span></td>
                                                                        <td><span onClick={() => incrementValue('0')} id="0">0</span></td>
                                                                        <td><span onClick={() => incrementValue('00')} id="double0">00</span></td>
                                                                        {/* <td><span onClick={() => incrementValue('000')} id="dot">000</span></td> */}
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr className="bet-btns">
                                                        <td colSpan="11">
                                                            <div className="btn-wraper">
                                                                <button className="btn cancel-bet">CANCEL</button>
                                                                {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={() => {
                                                                    {
                                                                        setBetLoader(true);
                                                                        getfancyClick({ eventid: receivedFancyballByBall?.finalResult?.[0]?.eventId, marketId: oddItem.marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.marketName, rate: oddsValue, odds: bklyClassValue === 'back' ? oddItem?.yesRate : oddItem?.noRate })
                                                                    }
                                                                }}>Place Bet</button> : <button className="btn place-bet" onClick={openPopup}>Login to continue</button>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {isBetLoader ?
                                            <div className="loader-sec loading">
                                                Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                            </div> : ''}

                                    </div>
                                </div>
                            ))}</>}
                    </div>

                    <FancyBookPopup isopen={fancyBookPopup} handleClose={handleClose} receivedBookFancy={receivedBookFancy} />
                </div>
                </AccordionItem>
                </Accordion>


            </> : ''}
        </>
    )
}

export default FancyBallByBall