import React from 'react'
import './servicebox.css'

// import serviceIcon1 from '../../assets/images/services-icon01.svg'
// import serviceIcon2 from '../../assets/images/services-icon04.svg'
// import serviceIcon3 from '../../assets/images/services-icon03.svg'

function ServiceBox({ servicesData }) {
    // console.log('servicesData',servicesData);
    return (
        <div className='row'>
            <div className='col'>
                <div className='service-box'>
                    <span className='icon'>
                        <svg fill="#000000" height="800px" width="800px" viewBox="0 0 512 512" >
<g transform="translate(0 1)">
	<g>
		<path d="M486.4,423.96c-50.93-27.008-89.996-42.152-124.772-47.958l3.599-4.948c1.707-1.707,2.56-4.267,1.707-6.827l-7.997-31.987
			c2.898-4.097,5.632-8.333,8.193-12.688c31.8-10.823,47.881-29.118,50.583-56.054c13.968-0.728,26.02-13.195,26.02-27.271v-64
			c0-14.012-11.147-26.429-25.75-27.26C416.104,95.671,394.368-1,256-1C117.629-1,95.895,95.675,94.016,144.97
			c-13.853,0.818-25.749,12.449-25.749,27.256v64c0,14.507,12.8,27.307,28.16,27.307h12.8c4.704,0,9.225-1.352,13.163-3.666
			c4.839,25.685,15.518,50.948,30.674,72.372l-7.997,31.987c0,1.707,0,4.267,1.707,6.827l3.599,4.948
			c-34.776,5.807-73.842,20.95-124.772,47.958C9.387,431.64,0,447.853,0,465.773v36.693C0,507.587,3.413,511,8.533,511h204.8h3.413
			h78.507h3.413h204.8c5.12,0,8.533-3.413,8.533-8.533v-36.693C512,448.707,501.76,432.493,486.4,423.96z M256.047,404.441
			l20.433,46.826h-41.245L256.047,404.441z M229.035,468.333h53.897l4.995,25.6h-64.013L229.035,468.333z M345.6,348.867
			l3.413,15.36l-8.717,11.969c-0.556,0.455-1.071,1.006-1.523,1.684l-35.495,49.147l-11.262,15.464l-23.005-51.761
			c28.058-3.422,53.044-17.722,73.077-38.212C343.272,351.324,344.444,350.111,345.6,348.867z M378.921,295.784
			c4.813-11.711,8.441-23.884,10.735-36.154c3.249,1.968,6.948,3.283,10.936,3.729C399.113,275.421,393.754,286.809,378.921,295.784
			z M425.813,171.373v64c0,5.12-4.267,10.24-10.24,10.24h-12.8c-5.12,0-10.24-4.267-10.24-10.24V229.4v-58.027
			c0-5.12,4.267-10.24,10.24-10.24h12.8C420.693,161.133,425.813,165.4,425.813,171.373z M256,16.067
			c52.597,0,141.118,17.17,144.924,128.992c-3.576,0.367-7.001,1.454-10.099,3.124C380.441,83.202,323.787,33.133,256,33.133
			c-67.778,0-124.426,50.055-134.821,115.022c-3.052-1.648-6.46-2.737-10.104-3.099C114.883,33.237,203.404,16.067,256,16.067z
			 M118.613,235.373c0,5.12-4.267,10.24-10.24,10.24h-12.8c-5.12,0-10.24-4.267-10.24-10.24v-64c0-5.12,4.267-10.24,10.24-10.24
			h12.8c5.12,0,10.24,4.267,10.24,10.24V235.373z M136.533,169.667C136.533,103.96,190.293,50.2,256,50.2
			s119.467,53.76,119.467,119.467V229.4c0,28.232-7.35,54.079-19.266,75.887c-20.368,5.908-49.669,9.446-91.667,9.446
			c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533c30.745,0,56.458-1.888,77.515-5.835
			c-0.523,0.523-1.046,1.046-1.569,1.569c-4.293,5.724-8.824,10.943-13.558,15.649c-20.093,18.719-44.058,30.058-67.152,31.181
			c-1.162-0.499-2.465-0.75-3.77-0.75c-1.312,0-2.494,0.257-3.554,0.761c-14.136-0.648-28.603-5.129-42.316-12.793
			c-13.893-7.982-26.962-19.489-38.61-34.048c-0.853-0.853-1.707-1.707-2.56-2.56l-0.498-0.166
			c-19.164-25.134-31.929-58.199-31.929-95.407V169.667z M242.988,390.73l-23.005,51.761l-11.262-15.464l-35.495-49.147
			c-0.272-0.272-0.572-0.541-0.884-0.806l-9.356-12.847l3.413-15.36c1.155,1.244,2.327,2.456,3.509,3.649
			C189.942,373.006,214.93,387.307,242.988,390.73z M17.067,465.773c0-11.093,5.973-21.333,16.213-26.453
			c54.393-28.896,93.548-43.399,128.428-47.731l50.922,70.018l-6.465,32.326H17.067V465.773z M494.933,493.933H305.835
			l-6.465-32.326l51.474-70.777c34.757,5.245,74.615,19.737,127.877,47.636c10.24,5.973,16.213,16.213,16.213,27.307V493.933z"/>
	</g>
</g>
</svg>
</span>
                    <h2>{servicesData?.[1]?.heading}</h2>
                </div>
            </div>
            <div className='col'>
                <div className='service-box'>
                    <span className='icon'><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32">
<g data-name="7. Wallet" id="_7._Wallet">
<path d="M30,15.18V11a3,3,0,0,0-3-3H25.5L19.8.4A1,1,0,0,0,18.41.19L13.35,3.88A6,6,0,0,0,3,8a3,3,0,0,0-3,3V29a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V24.82A3,3,0,0,0,32,22V18A3,3,0,0,0,30,15.18ZM18.79,2.39,23,8H11.08ZM9,4a4,4,0,0,1,2.71,1.06L7.67,8H5A4,4,0,0,1,9,4ZM27,30H3a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v4H25a5,5,0,0,0,0,10h3v4A1,1,0,0,1,27,30Zm3-8a1,1,0,0,1-1,1H25a3,3,0,0,1,0-6h4a1,1,0,0,1,1,1Z"/>
<path d="M25,19h0a1,1,0,1,0,1,1A1,1,0,0,0,25,19Z"/>
<path d="M5,12a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V13A1,1,0,0,0,5,12Z"/>
<path d="M5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18Z"/>
<path d="M5,24a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V25A1,1,0,0,0,5,24Z"/>
</g>
</svg></span>
                    <h2>{servicesData?.[2]?.heading}</h2>
                </div>
            </div>
            <div className='col'>
                <div className='service-box'>
                    <span className='icon'><svg width="800px" height="800px" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4472 1.10557C12.1657 0.964809 11.8343 0.964809 11.5528 1.10557L3.55279 5.10557C3.214 5.27496 3 5.62123 3 6V12C3 14.6622 3.86054 16.8913 5.40294 18.7161C6.92926 20.5218 9.08471 21.8878 11.6214 22.9255C11.864 23.0248 12.136 23.0248 12.3786 22.9255C14.9153 21.8878 17.0707 20.5218 18.5971 18.7161C20.1395 16.8913 21 14.6622 21 12V6C21 5.62123 20.786 5.27496 20.4472 5.10557L12.4472 1.10557ZM5 12V6.61803L12 3.11803L19 6.61803V12C19 14.1925 18.305 15.9635 17.0696 17.425C15.8861 18.8252 14.1721 19.9803 12 20.9156C9.82786 19.9803 8.11391 18.8252 6.93039 17.425C5.69502 15.9635 5 14.1925 5 12ZM16.7572 9.65323C17.1179 9.23507 17.0714 8.60361 16.6532 8.24284C16.2351 7.88207 15.6036 7.9286 15.2428 8.34677L10.7627 13.5396L8.70022 11.5168C8.30592 11.1301 7.67279 11.1362 7.28607 11.5305C6.89935 11.9248 6.90549 12.5579 7.29978 12.9446L10.1233 15.7139C10.3206 15.9074 10.5891 16.0106 10.8651 15.9991C11.1412 15.9876 11.4002 15.8624 11.5807 15.6532L16.7572 9.65323Z" fill="#000000"/>
</svg></span>
                    <h2>{servicesData?.[3]?.heading}</h2>
                </div>
            </div>
        </div>
    )
}

export default ServiceBox