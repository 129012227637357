import React, { useEffect, useRef, useState } from "react";
import Container from "../../components/common/Container";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CommissionPopup from "./CommissionPopup";
import Popup from "../../components/common/popup/Popup";

export default function MyCommissionEvent() {
  document.title = "Commission Report";
  const { eventId } = useParams();
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [plStatement, setPLStatement] = useState();
  const navigate = useNavigate();
  const [stackChange, setStackChange] = useState(false);
  const [eventIdClick, setEventIDClick] = useState("");

  const [loginSucessMsg, setLoginSucessMsg] = useState();
    const [errMsg, setErrmsg] = useState();
    const [msg, setMsg] = useState('');
  
      useEffect(() => {
        if(loginSucessMsg){
          const setloginsucessMsg = setTimeout(() => {
            setLoginSucessMsg(false);
          }, 2000);
    
          return () => {
            clearTimeout(setloginsucessMsg);
          };
        }
  
        if (errMsg) {
          const errMsgTime = setTimeout(() => {
              setErrmsg(false);
            }, 2000);
            return () => {
              clearTimeout(errMsgTime);
            };
        }
      },[eventIdClick]);

      const handleClaimCommission = (toClaimCommission) => {
        // if (toClaimCommission > 0) {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);
        
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            };
        
            fetch(`${process.env.REACT_APP_BASE_URL}/claimCommission`, requestOptions)
              .then(async (response) => {
                let paresData = await response.json();
                setMsg(paresData.msg);
                if (response.status === 200) {
                    setLoginSucessMsg(true);
                }else if(response.status === 401){
                    setMsg(paresData.message);
                    setErrmsg(true);
                }else{
                    setMsg(paresData.msg);
                    setErrmsg(true);
                }
                
                console.log("claimCommission - ", paresData);
                callPLStatement();
              })
              .catch((error) => {
                console.log(error);
              });
        // }
      }
    

  const callPLStatement = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/commissionPage?eventId=${eventId} `, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setPLStatement(paresData);
        console.log("commissionPage4 - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callPLStatement();
    }
  });
  const handleClose = () => {
    setStackChange(false);
  }


  return (
    <Container size="full">
    
      <div className="account-sec">
        <div className="acount-header-filter">
          <div className="title-wraper">
            <h3 className="title back-icon" onClick={()=>navigate(-1)}><i className="fa-solid fa-arrow-left"></i> Commission Report | {plStatement &&
              plStatement.commissionData &&
              plStatement.commissionData.length > 0 ? plStatement.commissionData[0].eventName :''}</h3>
          </div>
          <div className="right-filter">
            <div className="btns">
              <button className="commission btn" onClick={()=>handleClaimCommission(plStatement && plStatement.toClaimCommission)}>
                <i className="fa-solid fa-money-check-dollar"></i> Claim Commission
                ({plStatement && plStatement.toClaimCommission})
              </button>
            </div>
          </div>
        </div>
        <div className="account-table">
          <table border="1" cellPadding="10" cellSpacing="0">
            <thead>
              <tr>
                <th className="game">SR No.</th>
                <th>Market</th>
                <th>Commission Type</th>
                <th>Commission Points</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {plStatement &&
              plStatement.commissionData &&
              plStatement.commissionData.length > 0 ? (
                plStatement.commissionData.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.marketName}</td>
                    <td>{item.commissionType}</td>
                    <td>{item.commission}</td>
                    <td><button className="btn sm" onClick={()=>{setEventIDClick(item.marketId);setStackChange(true)}}>Details</button></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Popup
        popupClass={`alertMsgpopup ${loginSucessMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <Popup
        popupClass={`alertMsgpopup Error ${errMsg ? "open" : ""}`}
        content={
          <div className={`alert-msg`}>
            <span className="msg">{msg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <CommissionPopup isopen={stackChange} marketid={eventIdClick}  handleClose={handleClose}/>
    </Container>
  );
}
