// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-grid{display: flex; flex-wrap: wrap;}
.home-grid .col{width: 20%; padding:5px 5px;}
.home-grid .col img{border-radius: 15px; width: 100%;}
@media (max-width:999px) {
    .home-grid .col{width: 25%;}
}
@media (max-width:767px) {
    .home-grid .col{width: 33.33%;}
}
@media (max-width:480px) {
    .home-grid .col{width: 50%;}
    .home-grid .col { padding: 3px; }

}`, "",{"version":3,"sources":["webpack://./src/components/common/grid/gridboxs.css"],"names":[],"mappings":"AAAA,WAAW,aAAa,EAAE,eAAe,CAAC;AAC1C,gBAAgB,UAAU,EAAE,eAAe,CAAC;AAC5C,oBAAoB,mBAAmB,EAAE,WAAW,CAAC;AACrD;IACI,gBAAgB,UAAU,CAAC;AAC/B;AACA;IACI,gBAAgB,aAAa,CAAC;AAClC;AACA;IACI,gBAAgB,UAAU,CAAC;IAC3B,kBAAkB,YAAY,EAAE;;AAEpC","sourcesContent":[".home-grid{display: flex; flex-wrap: wrap;}\n.home-grid .col{width: 20%; padding:5px 5px;}\n.home-grid .col img{border-radius: 15px; width: 100%;}\n@media (max-width:999px) {\n    .home-grid .col{width: 25%;}\n}\n@media (max-width:767px) {\n    .home-grid .col{width: 33.33%;}\n}\n@media (max-width:480px) {\n    .home-grid .col{width: 50%;}\n    .home-grid .col { padding: 3px; }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
