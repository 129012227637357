import React, { useEffect, useState } from 'react'
import Container from '../../components/common/Container'
import Banner from '../../components/common/Banner';
import FeatureEvent from '../../components/common/FeatureEvent/FeatureEvent';
import Allsportdata from '../../components/allsportdata/Allsportdata';
import { Link } from 'react-router-dom';
import sidebarCard1 from '../../assets/images/cas-1.png'
import sidebarCard2 from '../../assets/images/cas-2.png'
import sidebarCard3 from '../../assets/images/cas-3.png'

export default function Election() {
  document.title = 'Election';
      const [matchdata, setMatchData] = useState([]);
      const [selectedTypes, setSelectedTypes] = useState("competitions");
      const handleTypeChange = (event) => {
        setSelectedTypes(event.target.value);
        fetchData(event.target.value);
      };
            useEffect(() => {
                // Fetch API data here
               
                fetchData();
            }, []);
            const fetchData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/games/getExchangeData?type=election`);
                    const data = await response.json();
                    setMatchData(data);
                } catch (error) {
                    console.error('Error fetching API data:', error);
                }
            };
    
  return (
    <Container size='full'>
        <div className='content-w-sidebar'>
        <div className='left-content'>
        <Banner image={`https://admin.${window.location.hostname}/img/Tennis_Top.webp`}/>
        <FeatureEvent />
        <div className="filter-btn-wrapper">
                <div className="filter-btn">
                  <span>
                    View by:
                    </span>
                    <select
                      name="filterby"
                      id="filterby"
                      value={selectedTypes}
                      onChange={handleTypeChange}
                    >
                      <option value="competitions" selected="">
                        Competitions
                      </option>
                      <option value="time">Time</option>
                    </select>
                  
                </div>
              </div>
        <Allsportdata matchData={matchdata} selectedTypes={selectedTypes} />
        </div>
        <div className='right-content sidebar'>
            <div className='sidebar-box'>
            <div className='title'>All Casino</div>
            <div className='sidebar-card'>
                <Link to='/'> <img src={sidebarCard1}/></Link>
                <Link to='/'> <img src={sidebarCard2}/></Link>
                <Link to='/'> <img src={sidebarCard3}/></Link>
            </div>
            </div>
        </div>
        </div>
    </Container>
  )
}
