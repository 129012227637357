import React, { useState, useEffect,useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../components/common/Container";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyPlStatement() {
  document.title = "PL Statement";
    const initialized = useRef(false);
    const token = sessionStorage.getItem("token");
    const [plStatement, setPLStatement] = useState();
    const navigate = useNavigate();

    const handleRowClick = (item) => {
        navigate(`/MyPlStatement/${item._id}`);
      };

    const callPLStatement = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getMyPLReport`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            setPLStatement(paresData);
            console.log("getMyPLReport - ", paresData);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callPLStatement();
          }
      });

  return (
    <Container size='full'>
      <div className="account-sec">
      <div className="acount-header-filter">
        <div className="title-wraper">
          <span className="v-border"></span>
          <h3 className="title">PROFIT/LOSS STATEMENT</h3>
        </div>
      </div>
      <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
  <thead>
    <tr>
      <th className="game">Game</th>
      <th>Total Bets</th>
      <th>Won</th>
      <th>Lost</th>
      <th>Void</th>
      <th>Open</th>
      <th>Profit/Loss</th>
    </tr>
  </thead>
  <tbody>
    {plStatement && plStatement.data2 && plStatement.data2.length > 0 && (
      <tr className="inner-heading">
        <td>Total</td>
        <td>{plStatement.data2[0].betCount}</td>
        <td>{plStatement.data2[0].wins}</td>
        <td>{plStatement.data2[0].losses}</td>
        <td>{plStatement.data2[0].void}</td>
        <td>{plStatement.data2[0].open}</td>
        <td>{plStatement.data2[0].profit}</td>
      </tr>
    )}
    {plStatement && plStatement.data && plStatement.data.length > 0 ? (
      plStatement.data.map((item, index) => (
        <tr
          key={index}
          onClick={() => handleRowClick(item)}
          style={{ cursor: "pointer" }}
        >
          <td>{item._id}</td>
          <td>{item.betCount}</td>
          <td>{item.wins}</td>
          <td>{item.losses}</td>
          <td>{item.void}</td>
          <td>{item.open}</td>
          <td>{item.profit}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="7" style={{ textAlign: "center" }}>
          No data available
        </td>
      </tr>
    )}
  </tbody>
</table>

      </div>
      </div>

      </Container>

  )
}
