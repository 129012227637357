import React, { useState, useEffect,useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Container from "../../components/common/Container";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyPlStatementMatch() {
    document.title = "PL Statement";
  const navigate = useNavigate();
    const initialized = useRef(false);
    const token = sessionStorage.getItem("token");
    const [plStatement, setPLStatement] = useState();
    const { eventname, matchname } = useParams();

  const { loggedIn } = useContext(PopupContext);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = sessionStorage.getItem('loggedIn');
    return storedValue === "true";
  });
  
  useEffect(()=>{
    if  (!sessionStorage.getItem("token")) {
      if (!loggedIn) {
        navigate('/');
      }
    }

},[isLoggedIn, loggedIn])

    const formatDatefromAPI = (isoDate) => {
      const date = new Date(isoDate);

      const optionsDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // This ensures the time is in 12-hour format with AM/PM
      };

      const formattedDate = date.toLocaleDateString('en-US', optionsDate);
      const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

      return `${formattedDate} at ${formattedTime}`;
    };

    const callPLStatement = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getMyPLReport?matchname=${matchname}&eventname=${eventname}`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            setPLStatement(paresData);
            console.log("getMyPLReport - ", paresData);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callPLStatement();
          }
      });

  return (
    <Container size='full'>
      <div className="account-sec">
      <div className="acount-header-filter">
        <div className="title-wraper">
          <h3 className="title back-icon" onClick={()=>navigate(-1)}><i className="fa-solid fa-arrow-left"></i> P/L Statement | {matchname}</h3>
        </div>
      </div>
      <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
     <thead>
     <tr>
        <th className="market">Market</th>
        <th className="time">Start time</th>
        <th className="time">Settled time</th>
        <th>Comm.</th>
        <th>Net Win</th>
     </tr>
        </thead>
        <tbody>
      {plStatement && plStatement.betsofthatMatch.map((item,index)=>(
        <tr key={index}>
            <td>{item.marketName}</td>
            <td>{formatDatefromAPI(item.date)}</td>
            <td>{item.settledate == null || item.settledate ==='' || item.settledate === '-' ? '-': formatDatefromAPI(item.settledate)}</td>
            <td>{item.thisCommision}</td>
            <td>{item.totalSumOfReturns}</td>
        </tr>
      ))}
      </tbody>
      </table>
      </div>
      </div>
      </Container>
  )
}
