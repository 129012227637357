import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Popup from "../../components/common/popup/Popup";
import { useEffect } from "react";

export default function AddPaymentMenthodPopup({ isopen, handleClose, update }) {
  const token = sessionStorage.getItem("token");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const initialized = useRef(false);
  const [paymentTab, setPaymentTab] = useState("tab1");


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  const handleTabChnage =(tab) => {
    setPaymentTab(tab);
  }

  const [formData, setFormData] = useState({
    tab1: {
      accountName: "",
      accountNumber: "",
      displayName: "",
      ifscCode: "",
      bankName: "",
      branchName: "",
      password: "",
    },
    tab2: {
      accountName: "",
      upiId: "",
      displayName: "",
      password: "",
    },
    tab3: {
      accountName: "",
      phoneNumber: "",
      displayName: "",
      password: "",
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [paymentTab]: {
        ...prev[paymentTab],
        [name]: value,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      [paymentTab]: {
        ...prev[paymentTab],
        [name]: "",
      },
    }));
  };

  const getMethodType = (type) => {
    if (type === "tab1") {
      return 'banktransferW';
    }else if (type === "tab2") {
      return 'upiW';
    }else if (type === "tab3") {
      return 'paytmW';
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    setErrors((prev) => ({
      ...prev,
      [paymentTab]: newErrors,
    }));

    if (Object.keys(newErrors).length === 0) {
      console.log("Submitted Data:", formData[paymentTab]);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const raw = JSON.stringify({
        accountholdername: formData[paymentTab].accountName,
        pmethod: getMethodType(paymentTab),
        accountnumber: formData[paymentTab].accountNumber ? formData[paymentTab].accountNumber : '',
        displayname: formData[paymentTab].displayName,
        ifsccode: formData[paymentTab].ifscCode ? formData[paymentTab].ifscCode : '',
        bankname: formData[paymentTab].bankName ? formData[paymentTab].bankName : '',
        branchname: formData[paymentTab].branchName ? formData[paymentTab].branchName : '',
        upiid: formData[paymentTab].upiId ? formData[paymentTab].upiId : '',
        phonenumber: formData[paymentTab].phoneNumber ? formData[paymentTab].phoneNumber :'',
        password: formData[paymentTab].password
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/payment/addpaymentmethoduserside`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
          if (response.ok) {
            setIsUpdate(true);
            setTimeout(() => {
              setIsUpdate(false);
              handleClose();
              update();
            }, 3000);
            setFormData({
              tab1: {
                accountName: "",
                accountNumber: "",
                displayName: "",
                ifscCode: "",
                bankName: "",
                branchName: "",
                password: "",
              },
              tab2: {
                accountName: "",
                upiId: "",
                displayName: "",
                password: "",
              },
              tab3: {
                accountName: "",
                phoneNumber: "",
                displayName: "",
                password: "",
              },
            })
          }else{
            setIsError(true);
            setErrorMsg(paresData.message);
            setTimeout(() => {
              setIsError(false);
            }, 3000);
          }
      })
      .catch((error) => {
        console.log(error);
      });

    } else {
      // Focus the first field with an error
      const firstErrorField = Object.keys(newErrors)[0];
      document.getElementsByName(firstErrorField)[0].focus();
    }
  };

  const [errors, setErrors] = useState({
    tab1: {},
    tab2: {},
    tab3: {},
  });

  const validateForm = () => {
    const newErrors = {};
    const currentFormData = formData[paymentTab];

    if (paymentTab === "tab1") {
      if (!currentFormData.accountName) newErrors.accountName = "Account Name is required.";
      if (!currentFormData.accountNumber) newErrors.accountNumber = "Account Number is required.";
      if (!currentFormData.displayName) newErrors.displayName = "Display Name is required.";
      if (!currentFormData.ifscCode) newErrors.ifscCode = "IFSC Code is required.";
      if (!currentFormData.bankName) newErrors.bankName = "Bank Name is required.";
      if (!currentFormData.branchName) newErrors.branchName = "Branch Name is required.";
      if (!currentFormData.password) newErrors.password = "Password is required.";
    } else if (paymentTab === "tab2") {
      if (!currentFormData.accountName) newErrors.accountName = "Account Name is required.";
      if (!currentFormData.upiId) newErrors.upiId = "UPI ID is required.";
      if (!currentFormData.displayName) newErrors.displayName = "Display Name is required.";
      if (!currentFormData.password) newErrors.password = "Password is required.";
    } else if (paymentTab === "tab3") {
      if (!currentFormData.accountName) newErrors.accountName = "Account Name is required.";
      if (!currentFormData.phoneNumber) newErrors.phoneNumber = "Phone Number is required.";
      if (!currentFormData.displayName) newErrors.displayName = "Display Name is required.";
      if (!currentFormData.password) newErrors.password = "Password is required.";
    }

    return newErrors;
  };

    const renderForm = () => {
        const currentErrors = errors[paymentTab];
        const currentFormData = formData[paymentTab];

        switch (paymentTab) {
            case "tab1":
                return (
                    <form method="post" className="addBankUser-form" onSubmit={(e)=>handleSubmit(e)}>
                    <div className="input-box">
                      <input
                        type="text"
                        name="accountName"
                        placeholder="Account Name"
                        autoComplete="off"
                        value={currentFormData.accountName}
                        onChange={handleChange}
                      />
                      {currentErrors.accountName && <span className="error">{currentErrors.accountName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="number"
                        name="accountNumber"
                        placeholder="Account Number"
                        autoComplete="off"
                        value={currentFormData.accountNumber}
                        onChange={handleChange}
                      />
                      {currentErrors.accountNumber && <span className="error">{currentErrors.accountNumber}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="displayName"
                        placeholder="Display Name"
                        autoComplete="off"
                        value={currentFormData.displayName}
                        onChange={handleChange}
                      />
                      {currentErrors.displayName && <span className="error">{currentErrors.displayName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="ifscCode"
                        placeholder="IFSC Code"
                        autoComplete="off"
                        value={currentFormData.ifscCode}
                        onChange={handleChange}
                      />
                      {currentErrors.ifscCode && <span className="error">{currentErrors.ifscCode}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="bankName"
                        placeholder="Bank Name"
                        autoComplete="off"
                        value={currentFormData.bankName}
                        onChange={handleChange}
                      />
                      {currentErrors.bankName && <span className="error">{currentErrors.bankName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="branchName"
                        placeholder="Branch Name"
                        autoComplete="off"
                        value={currentFormData.branchName}
                        onChange={handleChange}
                      />
                      {currentErrors.branchName && <span className="error">{currentErrors.branchName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="password"
                        name="password"
                        placeholder="Enter Your Password"
                        autocomplete="new-password" 
                        value={currentFormData.password}
                        onChange={handleChange}
                      />
                      {currentErrors.password && <span className="error">{currentErrors.password}</span>}
                    </div>
                    <div className="btn-sec">
                      <button type="submit" className="btn">Submit</button>
                    </div>
                  </form>
                );
            case "tab2":
                return (
                    <form method="post" className="addBankUser-form" onSubmit={(e)=>handleSubmit(e)}>
                    <div className="input-box">
                      <input
                        type="text"
                        name="accountName"
                        placeholder="Account Name"
                        autoComplete="off"
                        value={currentFormData.accountName}
                        onChange={handleChange}
                      />
                      {currentErrors.accountName && <span className="error">{currentErrors.accountName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="upiId"
                        placeholder="UPI Id"
                        autoComplete="off"
                        value={currentFormData.upiId}
                        onChange={handleChange}
                      />
                      {currentErrors.upiId && <span className="error">{currentErrors.upiId}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="displayName"
                        autoComplete="off"
                        placeholder="Display Name"
                        value={currentFormData.displayName}
                        onChange={handleChange}
                      />
                      {currentErrors.displayName && <span className="error">{currentErrors.displayName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="password"
                        name="password"
                        placeholder="Enter Your Password"
                        autocomplete="new-password" 
                        value={currentFormData.password}
                        onChange={handleChange}
                      />
                      {currentErrors.password && <span className="error">{currentErrors.password}</span>}
                    </div>
                    <div className="btn-sec">
                      <button type="submit" className="btn">Submit</button>
                    </div>
                  </form>
                );
            case "tab3":
                return (
                    <form method="post" className="addBankUser-form" onSubmit={(e)=>handleSubmit(e)}>
                    <div className="input-box">
                      <input
                        type="text"
                        name="accountName"
                        placeholder="Account Name"
                        autoComplete="off"
                        value={currentFormData.accountName}
                        onChange={handleChange}
                      />
                      {currentErrors.accountName && <span className="error">{currentErrors.accountName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        autoComplete="off"
                        value={currentFormData.phoneNumber}
                        onChange={handleChange}
                      />
                      {currentErrors.phoneNumber && <span className="error">{currentErrors.phoneNumber}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="text"
                        name="displayName"
                        placeholder="Display Name"
                        autoComplete="off"
                        value={currentFormData.displayName}
                        onChange={handleChange}
                      />
                      {currentErrors.displayName && <span className="error">{currentErrors.displayName}</span>}
                    </div>
                    <div className="input-box">
                      <input
                        type="password"
                        name="password"
                        placeholder="Enter Your Password"
                        autocomplete="new-password" 
                        value={currentFormData.password}
                        onChange={handleChange}
                      />
                      {currentErrors.password && <span className="error">{currentErrors.password}</span>}
                    </div>
                    <div className="btn-sec">
                      <button type="submit" className="btn">Submit</button>
                    </div>
                  </form>
                );
            default:
                return null;
        }
    };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleClose}
        className="menu-dropdown-popup stack-chnage-popup paymentDepositePopup manage-acccount-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> Add Payment Method
          </h2>
        </Modal.Header>
        <Modal.Body>
        <div className="choose-payment-option">
            <div className="payment-tab">
                <div className="payment-tab-list">
                    <span onClick={()=>handleTabChnage("tab1")} className={`img-payment add-Bank ${paymentTab === "tab1" ? 'active' :''}`}>Add Bank</span>
                    <span onClick={()=>handleTabChnage("tab2")} className={`img-payment add-Upi ${paymentTab === "tab2" ? 'active' :''}`}>Add UPI</span>
                    <span onClick={()=>handleTabChnage("tab3")} className={`img-payment add-Paytm ${paymentTab === "tab3" ? 'active' :''}`}>Add Paytm</span>
                </div>
            </div>
        </div>

        <div className="enter-withdraw-detail">
            {renderForm()}
        </div>
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">Payment Method Successfully!!</span>
          </div>
        }
      />

      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}