import React, { useEffect, useRef, useState } from 'react'
import './scoreboard.css'
import MobileLiveStreamTab from './MobileLiveStreamTab';
import StringToHtml from '../../StringToHtml';

function Scoreboard({scoreboardData, receivedBetlistData,eventId}) {
  const spId = scoreboardData?.sportId;
  const evId = scoreboardData?.eventId;
  const [url, setUrl] = useState("");

  const [eventName, setEventName] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [matchStatus, setMatchStatus] = useState('');
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    callAPI();
    }
    // if (scoreboardData) {
      callScoreAPI(scoreboardData); 
    // }
  },[scoreboardData,url]);

  const callAPI =()=>{
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch(`${process.env.REACT_APP_BASE_URL}/getmatchdata?matchId=${eventId}`, requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
      setCompetitionName(paresData.match.competitionName);
      setEventName(paresData.match.eventName);
      setEventDate(paresData.match.openDate);
      setMatchStatus(paresData.match.status);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const callScoreAPI =(scoreboardData)=>{
    // console.log(scoreboardData,"............scoreboardData");
    
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
          "whiteLabelName": window.location.hostname,
          "eventId":scoreboardData?.eventId ? scoreboardData?.eventId : evId,
          "sportId":scoreboardData?.sportId ? scoreboardData?.sportId : spId
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };
    
    fetch(`${process.env.REACT_APP_BASE_URL}/getScoreCard`, requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
      setUrl(paresData.scorecard);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getIframe = (url) => {
      return <StringToHtml htmlString={url}  />
  }

  return (
    <div className='live-score'>
      <div className="heading">
        <div className="heading-text sport-name"><span className='series-name'>{competitionName}</span> <span className='seprator'>/</span> <strong>{eventName}</strong></div>
        <div className="heading-text time"><i className="fa-regular fa-clock"></i> {eventDate && (
          <>
            {new Date(eventDate).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
            {" at "}
            {new Date(eventDate).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </>
        )}</div></div>
      {matchStatus == 'IN_PLAY' ? <div className='scoreboard'>
        {getIframe(url)}
      </div> : ''}
      <MobileLiveStreamTab matchStatus={matchStatus} spId={spId} evId={evId} receivedBetlistData= {receivedBetlistData} url={url} />
    </div>
  )
}

export default Scoreboard